import {
    // get,
    post
} from "./api";
/**
 * 评估人员查询
 * @param params
 * @returns {*}
 */
export const gettalentUser = (params) => post('/v1/talent/selectUser', params);
/**
 * 公司信息获取
 * @param params
 * @returns {*}
 */
export const getqueryCompanyList = (params) => post('/v1/talent/queryCompanyList', params);
/**
 * 人员评才
 * @param params
 * @returns {*}
 */
export const submittalent = (params) => post('/v1/talent/saveUserAssessReport', params);
/**
 * 评估详情查看
 * @param params
 * @returns {*}
 */
export const selectUserAssessReport = (params) => post('/v1/talent/selectUserAssessReport', params);
/** 
 * 添加企业
 * @param params
 * @returns {*}
 */
export const addCompany = (params) => post('/v1/talent/saveCompany', params);
/**
 * 获取人员评估审核初始数据
 * @param params
 * @returns {*}
 */
export const getOriginalAudit = (params) => post('/v1/talent/assessUserAgain', params);