<template>
	<div class="container">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">{{ assessForm.userName }}-评估审核</div>
		</div>
		<!-- <div class="divider_line"></div> -->
		<div class="container_box">
			<!-- 原始评估模块 -->
			<div class="section_assessment">
				<!-- <div class="section_assessment_title">原始评估</div> -->
				<div class="viewbox">
					<div class="level_box">
						<div class="level_box_levelbox">
							<span class="level_box_now"
								>评估职级：{{
									rankName == 0
										? '战略决策层'
										: rankName == 1
										? '战略执行层'
										: rankName == 2
										? '部门运营层'
										: rankName == 3
										? '业务执行层'
										: '非管理层'
								}}级</span
							><span class="level_box_old">原有职级：{{ originRankName || '暂无评' }}级</span>
						</div>
					</div>
					<talent-form :form="assessForm" :isDisabled="true"></talent-form>
				</div>

				<div class="evaluator">
					<!-- <span class="evaluator_name">评估人：{{evaluateUsername}}</span><span>评估时间：{{evaluateTime}}</span> -->
					<div>
						<span class="evaluator_name">评估人：{{ evaluateUsername }}</span
						><span>评估时间：{{ evaluateTime }}</span>
					</div>
					<div style="margin-top: 7px">
						<span class="evaluator_name" v-show="auditor">审核人：{{ auditor }}</span
						><span v-show="auditTime">审核时间：{{ auditTime }}</span>
					</div>
				</div>
			</div>
			<!-- -->
			<!-- 评估审核模块 -->
			<div class="section_audit">
				<!-- <div class="section_assessment_title">评估审核</div> -->
				<div class="viewbox">
					<div class="level_box">
						<div class="level_box_levelbox">
							<span class="level_box_now"
								>评估职级：{{
									rankName == 0
										? '战略决策层'
										: rankName == 1
										? '战略执行层'
										: rankName == 2
										? '部门运营层'
										: rankName == 3
										? '业务执行层'
										: '非管理层'
								}}级</span
							><span class="level_box_old">原有职级：{{ originRankName || '暂无评' }}级</span>
						</div>
					</div>
					<talent-form :form="assessForm" :isDisabled="false"></talent-form>
				</div>
			</div>
		</div>
		<!-- <div class="footer_btn">
      <el-button class="footer_btn_cancel" type="info" size="medium" @click="handleCancel">取消</el-button>
      <el-button type="primary" size="medium" @click="handleConfirm">提交</el-button>
    </div> -->
	</div>
</template>

<script>
import * as api from '@/api/talentassessment';
import talentForm from '../talentForm.vue';
export default {
	components: {
		talentForm
	},
	data() {
		return {
			userId: '',
			// 原始评估的数据
			talentType: '',
			// userName:"",
			// Viewform: {
			//   id: "",
			//   userName: "",
			//   managerStation: ""
			// },
			rankName: '0',
			rankLevel: '1',
			originRankName: '',
			rankNote: '',
			competencyNote: '',
			synthesizeAppraise: '',
			appointment: '',
			// Postproposal:"",
			firstResport: {},
			againResport: {},
			appointmentNote: '',
			evaluateUsername: '',
			evaluateTime: '',
			auditor: '',
			auditTime: '',

			talentTypeAgain: '',
			rankNameAgain: '0',
			rankLevelAgain: 1,
			rankNoteAgain: '',
			competencyNoteAgain: '',
			synthesizeAppraiseAgain: '',
			appointmentAgain: '',
			PostproposalAgain: '',
			appointmentNoteAgain: '',
			assessForm: {
				rankName: '',
				rankLevel: '',
				rankNote: '',
				strategy: '',
				strategyNote: '',
				changeExpert: '',
				changeNote: '',
				flowExpert: '',
				flowNote: '',
				employeeAdvocate: '',
				advocateNote: '',
				initiative: '',
				initiativeNote: '',
				conceptThinking: '',
				conceptNote: '',
				influence: '',
				influenceNote: '',
				achievementGuide: '',
				guideNote: '',
				hardiness: '',
				hardinessNote: '',
				synthesizeAppraise: '',
				competencyNote: '',
				appointment: '',
				appointmentNote: '',
				talentType: ''
			}
		};
	},
	mounted() {
		this.userId = this.$route.query.id;
		this.getFormDate();
	},
	watch: {
		rankNameAgain(val) {
			if (val != 0) {
				this.competencyNoteAgain = '';
			}
		}
	},
	methods: {
		getFormDate() {
			// 这里取数据取的是人才评估详情的数据
			const params = {
				request: {
					id: this.userId,
					type: this.$route.query.status
				},
				sign: ''
			};

			api.selectUserAssessReport(params).then((res) => {
				if (res) {
					this.rankName = res.rankName;
					this.rankLevel = res.rankLevel;
					this.originRankName = res.originRankName;
					// 评估审核
					this.rankNameAgain = res.rankName;
					this.rankLevelAgain = res.rankLevel;
					this.evaluateUsername = res.evaluateUsername;
					this.evaluateTime = res.evaluateTime;

					for (const key in res) {
						this.assessForm[key] = res[key];
					}
				}
			});
		},
		handleCancel() {
			this.$router.go(-1);
		}
		// handleConfirm() {
		//   // 提交数据,弹框提示
		//   // 拼装数据
		//   let params = {
		//       request:{
		//         userId: this.userId,
		//         rankName: this.rankNameAgain,
		//         rankLevel: this.rankLevelAgain,
		//         rankNote: this.rankNoteAgain,
		//         strategy: this.textObjAgain[0].radio,
		//         strategyNote: this.textObjAgain[0].value,
		//         changeExpert: this.textObjAgain[1].radio,
		//         changeNote: this.textObjAgain[1].value,
		//         flowExpert: this.textObjAgain[2].radio,
		//         flowNote: this.textObjAgain[2].value,
		//         employeeAdvocate: this.textObjAgain[3].radio,
		//         advocateNote: this.textObjAgain[3].value,
		//         initiative: this.QualityAgain[0].radio,
		//         initiativeNote: this.QualityAgain[0].value,
		//         conceptThinking: this.QualityAgain[1].radio,
		//         conceptNote: this.QualityAgain[1].value,
		//         influence: this.QualityAgain[2].radio,
		//         influenceNote: this.QualityAgain[2].value,
		//         achievementGuide: this.QualityAgain[3].radio,
		//         guideNote: this.QualityAgain[3].value,
		//         hardiness: this.QualityAgain[4].radio,
		//         hardinessNote: this.QualityAgain[4].value,
		//         synthesizeAppraise: this.synthesizeAppraiseAgain,
		//         competencyNote:this.competencyNoteAgain,
		//         appointment:this.appointmentAgain,
		//         appointmentNote:this.appointmentNoteAgain,
		//         talentType: this.talentTypeAgain,
		//       }

		//   };
		//   // console.log(params);
		//   api.submittalent(params).then(res=>{
		//       if(res&&res.code==0){
		//         this.$message.success("评估审核提交成功");
		//         this.$router.push({name:"TalentAssessmentCenter"});
		//       }
		//   });

		// }
	}
};
</script>

<style lang="scss">
// .container .container_box .qualityevaluation .Postproposal_input {
//   margin-top: 20px;
// }

// .container .container_box .qualityevaluation .el-radio__input {
//   display: inline-block;
// }
// .container .container_box .qualityevaluation .el-radio__label {
//   display: inline-block;
// }
// 修改左边原始评估样式
// .container {
//   background-color: #fff;
//   .container_box {
//     .el-radio__input.is-disabled+span.el-radio__label {
//       color: #606266;
//     }
//   }
// }
// 覆盖原来elementUi的样式
.el-radio {
	color: #3f3f3f;
}
.container .container_box .el-radio__input.is-disabled + span.el-radio__label {
	color: #3f3f3f;
}
</style>

<style lang="scss" scoped>
.container {
	color: #484848;
	// background-color: #F6F7FB;
	.level_box {
		display: flex;
		// background-color: pink;
		.level_box_now {
			color: #4a8df0;
			font-weight: bold;
			font-size: 18px;
		}
		.level_box_old {
			color: #ccc;
			font-weight: bold;
			font-size: 18px;
			margin-left: 40px;
		}
		.level_box_levelbox {
			flex: 1;
		}
	}
	top: -24px;
	// }
	.tw24 {
		display: block;
		// position: relative;
		// top: -24px;
	}
	.btn_back {
		font-size: 16px;
		color: #484848;
		margin-top: 20px;
		margin-left: 30px;
	}
	.titlebox {
		text-align: center;
		line-height: 17px;
		background: #fff;
		font: bold 18px/25px 'Microsoft YaHei';
		padding: 14px 40px;
		> .el-button {
			float: left;
			color: #484848;
			transform: translateY(-8px);
			font-size: 16px;
		}
		text-align: center;
		line-height: 17px;
		font: bold 18px/25px 'Microsoft YaHei';
		margin: 20px;
	}
	.container_box {
		display: flex;
		padding: 30px 0 0 0;
		background: #fff;
		margin: 0 20px;
		.el-radio__label {
			color: #3f3f3f;
		}
	}
	.section_assessment {
		width: 50%;
		// background-color: pink;
		// overflow-x:scroll;
		// word-break: break-word;
		border-right: 1px solid #ccc;
		// .section_assessment_title {
		//   text-align: center;
		//   padding: 20px 0;
		//   font:bold 18px/25px "Microsoft YaHei";
		// }
		.evaluator {
			text-align: left;
			font: bold 14px/23px 'Microsoft YaHei';
			padding: 20px 0px;
			padding-left: 40%;
			.evaluator_name {
				margin-right: 20px;
				font-weight: bold;
				min-width: 113px;
				display: inline-block;
			}
		}
	}
	.section_audit {
		width: 50%;
		// overflow-x:scroll;
		// .section_assessment_title {
		//   text-align: center;
		//   padding: 20px 0;
		//   font:bold 18px/25px "Microsoft YaHei";
		// }
	}
	// .footer_btn {
	//   // margin-top: 20px;
	//   padding-top: 20px;
	//   padding-left: 25%;
	//   padding-right: 25%;
	//   padding-bottom: 10px;
	//   // padding: 0 25%;
	//   background-color: #fff;
	//   margin-left: 20px;
	//   margin-right: 20px;
	//   margin-bottom: 20px;
	//   text-align: center;
	//   .el-button {
	//     width: 100px;
	//   }
	//   .footer_btn_cancel {
	//     margin-right: 10%;
	//   }
	// }
	// .Postproposal {
	//   margin-top: 10px;
	//   .el-textarea {
	//     margin-left: 0;
	//     display: block;
	//     width: 87%;
	//   }
	//   .radioArr {
	//       width: 80%;
	//       margin-left: 10px;
	//     .el-radio {
	//       width: 17%;
	//       margin: 10px 5px;
	//     }
	//   }
	// }
}
</style>
<style lang="scss">
.section_assessment,
.section_audit {
	> .el-button {
		float: left;
		color: #484848;
		transform: translateY(-8px);
		font-size: 16px;
	}
	// .titlebox {
	//     text-align: center;
	//     line-height: 17px;
	//     font:bold 18px/25px "Microsoft YaHei";
	//     margin-bottom: 20px;
	// }
	.viewbox {
		// padding:30px 0 0 140px;
		padding: 10px 20px 0 40px;
		width: 100%;
		.el-radio {
			margin-right: 20px;
		}
		> p {
			color: #409eff;
			font-weight: bold;
			// font-size: 18px;
			font: bold 18px/25px 'Microsoft YaHei';
		}
		> div {
			margin-bottom: 14px;
			font: bold 14px/25px 'Microsoft YaHei';
			.viewbox_position {
				color: #3f3f3f;
				font-weight: normal;
			}
			span {
				font-weight: normal;
			}
		}
		.title {
			font: bold 18px/25px 'Microsoft YaHei';
		}
		.ptitle {
			font: bold 14px/25px 'Microsoft YaHei';
			margin-bottom: 10px;
		}

		.el-textarea {
			width: 87%;
			margin-left: 0px;
			vertical-align: middle;
			.el-textarea__inner {
				background-color: #f9fbff;
				// width: 380px;
			}
		}
		> .el-textarea {
			width: 87%;
			margin-left: 0;
		}
		.radioArr {
			margin-left: 30px;
			margin-bottom: 10px;
			.el-radio {
				margin-right: 40px;
			}
		}
	}
	// .qualityevaluation {
	//     // padding:30px 0 0 140px;
	//     padding:30px 0 0 40px;
	//     // width: 800px;
	//     width: 100%;
	//     div {
	//         font-weight: bold;
	//         span {
	//             font-weight: normal;
	//             font-size: 14px;
	//         }
	//     }
	//     .title {
	//         font:bold 18px/25px "Microsoft YaHei";
	//         margin-bottom: 10px;
	//     }
	//     .ptitle {
	//         font: bold 14px/25px "Microsoft YaHei";
	//         margin: 10px 0;
	//     }
	//     .el-textarea {
	//         width: 81%;
	//         margin:5px 10px;
	//         vertical-align: middle;
	//         .el-textarea__inner {
	//           background-color: #F9FBFF;
	//         }
	//     }
	//     >.el-textarea {
	//         width: 87%;
	//         margin-left: 0;
	//     }
	//     .radioArr {
	//         width: 100%;
	//         .el-radio {
	//             width: 100%;
	//             margin: 10px 0;
	//             // 下面这个样式会影响人才类型的样式
	//             // white-space: normal;
	//         }
	//     }
	//     .Talentstype{
	//         margin-top: 15px;
	//         .radioArr {
	//             width: 80%;
	//             margin-left: 10px;
	//             .el-radio {
	//                 width: 14%;
	//                 margin: 10px 5px;
	//             }
	//         }
	//     }
	//     .footer_btn{
	//         margin-top: 20px;
	//         padding: 0 25%;
	//         .el-button{
	//             width: 200px;
	//         }
	//     }
	// }
	// .Postproposal {
	//     margin-top: 15px;
	//     .el-textarea {
	//         margin-left: 0;
	//         display: block;
	//         width: 87%;
	//         .el-textarea__inner {
	//           background-color: #F9FBFF;
	//         }
	//     }
	// }
}
</style>
